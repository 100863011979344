<template>
  <div class="thumbnails">
    <img v-for="(item, index) in items.slice(0, galleryLimit)"
         :src="item['url']"
         :alt="item['alt']"
         @click="updatePreviewImageAtts(index)"
    >
  </div>
  <div class="preview">
    <img :src="previewSrc" :alt="previewAlt" @click="openLightboxOnSlide(previewIndex + 1)"/>
  </div>
  <Teleport :to="teleportTo">
    <FsLightbox
        :toggler=toggler
        :slide=slide
        :sources=sources
    />
  </Teleport>
</template>

<script>
import FsLightbox from "fslightbox-vue/v3";
import {Teleport} from "vue";

export default {
  components: {
    FsLightbox,
    Teleport,
  },

  props: {
    items: {
      type: Array,
      default: []
    },
    name: {
      type: String,
    },
    teleportTo: {
      type: String,
      default: 'body'
    }
  },

  data() {
    return {
      toggler: false,
      slide: this.start,
      previewSrc: this.items[0]['url'],
      previewAlt: this.items[0]['alt'],
      previewIndex: 0,
    }
  },

  mounted() {
    let togglesEls = document.querySelectorAll('.lightbox-trigger[data-lightbox-name="' + this.name + '"]');
    for (let togglesEl of togglesEls) {
      togglesEl.addEventListener('click', (e) => {
        this.toggler = !this.toggler;
      });
    }
  },
  computed: {
    sources() {
      return this.items.map(item => item['url']);
    },
  },
  methods: {
    openLightboxOnSlide(number) {
      this.slide = number;
      this.toggler = !this.toggler;
    },
    updatePreviewImageAtts(itemIndex) {
      this.previewSrc = this.items[itemIndex]['url'];
      this.previewAlt = this.items[itemIndex]['alt'];
      this.previewIndex = itemIndex;
    }
  }
};
</script>
