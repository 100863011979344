import CPTArchive from "./CPTArchive";
import Gallery from "./Gallery";
import Card from "./Card";
import Carousel from "./carousel/Carousel";

/**
 * Register global Vue components below and they will be auto-registered
 * with the root instance.
 */
export default {
    'cpt-archive': CPTArchive,
    'pa-carousel': Carousel,
    'pa-gallery': Gallery,
    'pa-card': Card
};
